<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.add.click">添加配件类别</en-button>
    </template>

    <flex-box>
      <template #default="{ height }">
        <table-dynamic
          :height="height"
          code="GDSCGYFD"
          :data="table.data"
          :loading="table.loading"
          :paging="table.paging"
          :method="table.get"
          pagination
          :config="table.config"
        >
          <template #OPERATION="{ row }: { row: EnocloudCommonDefinitions['GoodsCategoryDto'] }">
            <button-delete :method="table.operation.delete.click" :params="row">删除</button-delete>
          </template>
          <template #NAME="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionCategoryDto'] }">
            <en-button type="primary" link @click="table.operation.name.click(row)">{{ row.name }}</en-button>
          </template></table-dynamic
        >
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑配件类别' : '添加配件类别'">
    <en-form :model="detail.form.data" :rules="detail.form.rules" :loading="detail.form.loading" :ref="setRef('detailForm')">
      <en-form-item label="类别名称" prop="name">
        <en-input v-model="detail.form.data.name"></en-input>
      </en-form-item>
      <en-form-item label="排序" prop="seq">
        <en-input v-model="detail.form.data.seq"></en-input>
      </en-form-item>
      <en-form-item label="备注" prop="comment">
        <en-input v-model="detail.form.data.comment" maxlength="200" type="textarea" show-word-limit></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },

      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/goods/category',
            data: 'array',
            loading: true,
            pagination: true
          },
          delete: {
            action: 'DELETE /enocloud/common/goods/category/:categoryId',
            loading: true
          }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnocloudCommonDefinitions['GoodsCategoryDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            },
            name: {
              click(row: EnocloudCommonDefinitions['GoodsCategoryDto']) {
                this.detail.form.init()
                this.detail.form.data.id = row.id
                this.detail.form.get()
                this.detail.visible = true
              }
            }
          }
        },
        config: {
          NAME: { header: { filter: { type: 'text', field: 'name' } } },
          COMMENT: { header: { filter: { type: 'text', field: 'comment' } } }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              seq: ''
            },
            ajax: {
              get: {
                action: 'GET /enocloud/common/goods/category',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/common/goods/category',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/common/goods/category',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            rules: {
              name: [{ required: true, message: '请输入类别名称' }]
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
